import type { AppConfigNetworkFee } from '@noah-labs/shared-schema-gql';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export type TpConfig = {
  appStage: string;
  enableLoaderAnimations: boolean;
  isProd: boolean;
  networkFee: AppConfigNetworkFee;
};

dayjs.extend(duration);

let config: TpConfig | undefined;

export function setConfig(cfg: TpConfig): void {
  config = cfg;
}

export function getConfig(): TpConfig {
  if (!config) {
    throw new Error(
      'config for feature-wallet is not set, please call `setConfig()` at the start of your application',
    );
  }
  return config;
}
